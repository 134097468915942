import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button, { ButtonVariant } from "../components/Button";
import Layout from "../components/Layout";
import Section from "../components/Section";

const Communaute = () => {
  return (
    <Layout
      title="Communauté"
      metaTitle="Une communauté de designers responsables"
      metaTitleSuffix="Rejoins le mouvement"
      description="Intégrez une communauté d’ux designers et développer vos compétences, donner du sens à vos pratiques et participer à des événements inédits"
    >
      <p className="mt-main">
        Donut Panic, c’est aussi une communauté de passionnés qui échangent et
        partagent leurs connaissances sur le thème du design. Vous êtes designer
        en activité ou étudiant ? Rendez-vous sur notre serveur Discord pour
        rejoindre la discussion ou laissez-vous happer par les trépidants sujets
        abordés dans nos podcasts.
      </p>

      <Section title="Développez vos connaissances auprès de designers professionnels">
        <Button
          variant={ButtonVariant.discord}
          text="Rejoindre le discord"
          className="mx-auto mt-main"
          textClassName="border border-discord"
        />
        <p className="mt-main">
          Vous y retrouverez des sujets actuels et passionants tels que :
        </p>
        <ul className="mt-6 ml-2">
          <li className="pt-1">🍩 Le design éthique</li>
          <li className="pt-1">🍩 L’accessibilité et le design inclusif</li>
          <li className="pt-1">🍩 L’UX writing</li>
          <li className="pt-1">🍩 La recherche</li>
        </ul>
        <p className="mt-6">
          mais aussi de nombreux autres centres d’intérêt partagés par les
          membres de notre communauté comme la cause animale 🐶, la nourriture
          🍲, les fleurs 🌺, etc.
        </p>
      </Section>

      {/* cette section est masquée pour l'instant, car, pas encore de contenu */}
      {false && (
        <Section title="Des formats inédits pour en apprendre davantage">
          <div className="flex shadow-team rounded-2xl mt-main">
            <div>
              <StaticImage
                src="../images/icons/mic.png"
                alt="microphone"
                className="my-12 mx-7 w-14"
              />
            </div>
            <div className="flex flex-col my-4">
              <h5 className="my-4 text-xl font-bold lg:text-2xl">Podcasts</h5>
              <p className="my-4">
                La connaissance à portée de main, et d’oreille ! 👂
              </p>
            </div>
          </div>
          <div className="flex shadow-team rounded-2xl mt-main">
            <div>
              <StaticImage
                src="../images/icons/mic.png"
                alt="microphone"
                className="my-12 mx-7 w-14"
              />
            </div>
            <div className="flex flex-col my-4">
              <h5 className="my-4 text-xl font-bold lg:text-2xl">
                Chaîne Youtube
              </h5>
              <p className="my-4">
                Optimisez votre pratique et découvrez les tendances
              </p>
            </div>
          </div>
          <div className="flex shadow-team rounded-2xl mt-main">
            <div>
              <StaticImage
                src="../images/icons/mic.png"
                alt="microphone"
                className="my-12 mx-7 w-14"
              />
            </div>
            <div className="flex flex-col my-4">
              <h5 className="my-4 text-xl font-bold lg:text-2xl">Bonus</h5>
              <p className="my-4">Jeux de société, outils, ateliers...</p>
            </div>
          </div>
        </Section>
      )}
    </Layout>
  );
};

export default Communaute;
